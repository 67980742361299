/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material';
import { useQueryParam, StringParam } from 'use-query-params';
import { ZIGPOLL_ACCOUNT_ID } from '../settings';
import Layout from '../components/layout';
import Button from '../components/button';
import SEO from '../components/seo';
import theme from '../theme';

declare global {
  interface Window {
    Zigpoll: any;
  }
}

const ZigPollContainer = styled('div')({
  '& #zigpoll-embed-wrapper #zigpoll-embed-frame': {
    borderStyle: 'none !important',
    maxWidth: '640px !important',
    maxHeight: '100% !important',
    top: '0 !important',
    left: '0 !important',
    margin: '2.5rem !important',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto !important',
    },
  },
  '& h3': {
    fontFamily: theme.typography.h3.fontFamily,
  },
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  margin: '0 40px',
  paddingBottom: '1rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    margin: '0.5rem',
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#45C7FF',
  color: theme.palette.greyscale.black,
  marginRight: '8px',
  '&:hover, &:active &:focus': {
    backgroundColor: '#45C7FF',
    opacity: 0.85,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
    marginTop: '8px',
    marginBottom: '8px',
    marginRight: '0',
  },
});

const Loading = styled('div')({
  margin: '2.5rem',
});

const Survey: React.FC = () => {
  const [customerId] = useQueryParam('shopify_customer_id', StringParam);
  const [orderId] = useQueryParam('shopify_order_id', StringParam);
  const [customerEmail] = useQueryParam('shopify_customer_email', StringParam);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      customerId &&
      orderId &&
      customerEmail
    ) {
      const waitForZigpoll = setTimeout((): void => {
        if (window.Zigpoll) {
          setLoading(false);
          window.Zigpoll.metadata = {
            SHOPIFY_ORDER_ID: orderId,
            SHOPIFY_CUSTOMER_ID: customerId,
            SHOPIFY_CUSTOMER_EMAIL: customerEmail,
          };
          window.Zigpoll.refresh();
        } else {
          return clearTimeout(waitForZigpoll);
        }
      }, 1000);

      return () => clearTimeout(waitForZigpoll);
    }
    setLoading(false);
  }, [customerId, orderId, customerEmail]);

  return (
    <Layout hideFooter mainStyles={{ paddingBottom: '96px' }}>
      <SEO title="Survey" description="Survey" />
      <Helmet>
        <script type="application/javascript">
          {`
            ;(function () {
              window.Zigpoll = {
                accountId: '${ZIGPOLL_ACCOUNT_ID}',
              };
            }());
          `}
        </script>
        <script src="//cdn.zigpoll.com/static/js/main.js" />
      </Helmet>
      <ZigPollContainer id="zigpoll-embed-target" />
      {loading && <Loading>...Loading survey</Loading>}
      <ButtonContainer>
        <StyledButton
          href="/user/support"
          variant="primary"
          text="View support"
          sx={{
            height: '36px',
          }}
        />
        <Button
          href="/"
          variant="ghost"
          text="Shop"
          sx={{
            color: '#45C7FF',
            height: '36px',
          }}
        />
      </ButtonContainer>
    </Layout>
  );
};

export default Survey;
